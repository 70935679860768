// CLPL-19281: AA marketing content footer uses different content_group_id based on users card status.
// If no content loaded into "card-specific" content group, default to content from the current content group.
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { fetchPlacements } from 'core/actions/ocapi';
import { useDefineContentGroupIds } from './useDefineContentGroupIds';
import {
  selectCreditCardMarketingBannerConfig, makeSelectCreditCardMarketingBannerPlacement,
  makeSelectCreditCardMarketingBannerIsLoaded, makeSelectCreditCardMarketingBannerIsLoading,
} from '../selectors';

export const useGetCreditCardMarketingBanner = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { contentGroupIds } = useDefineContentGroupIds();
  const contentGroupId = contentGroupIds[activeIndex];

  const selectCreditCardMarketingBannerPlacement = useMemo(makeSelectCreditCardMarketingBannerPlacement, []);
  const selectCreditCardMarketingBannerIsLoaded = useMemo(makeSelectCreditCardMarketingBannerIsLoaded, []);
  const selectCreditCardMarketingBannerIsLoading = useMemo(makeSelectCreditCardMarketingBannerIsLoading, []);

  const config = useSelector(selectCreditCardMarketingBannerConfig);
  const placement = useSelector(state => selectCreditCardMarketingBannerPlacement(state, contentGroupId));
  const isLoaded = useSelector(state => selectCreditCardMarketingBannerIsLoaded(state, contentGroupId));
  const isLoading = useSelector(state => selectCreditCardMarketingBannerIsLoading(state, contentGroupId));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isLoaded && !isLoading) {
      const { api: { params, options } } = config;
      dispatch(fetchPlacements(
        { ...params, content_group_id: contentGroupId },
        { ...options, namespace: contentGroupId },
      ));
    }
  }, [activeIndex]);

  useEffect(() => {
    const isLastContentGroupId = activeIndex >= contentGroupIds.length - 1;
    if (isLoaded && isEmpty(placement) && !isLastContentGroupId) {
      setActiveIndex(activeIndex + 1);
    }
  }, [isLoaded]);

  return {
    placement,
  };
};

import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import ComponentErrorBoundary from 'core/modules/ErrorBoundaries/ComponentErrorBoundary';
import { addExternalAttributesIfNeeded } from 'core/utils/helpers/externalLink';
import { selectSiteUrl } from 'core/selectors/app';
import { selectIsMobileMQ } from 'core/modules/MediaQueries/selectors';

import config from './configs';

import './CreditCardMarketingBanner.scss';

const { hooks: { useGetCreditCardMarketingBanner } } = config;

function CreditCardMarketingBanner() {
  const siteUrl = useSelector(selectSiteUrl);
  const isMobileMQ = useSelector(selectIsMobileMQ);

  const { placement } = useGetCreditCardMarketingBanner();

  const {
    clickUrl, text, callToActionText, rolloverCopy, shortSummaryText, image, imageAlternateText = '',
  } = placement;
  const {
    shouldShowButtonView, shouldShowCardTerms, getShowCardTip, shouldShowCardHeading,
  } = config;

  const showCardTip = getShowCardTip(isMobileMQ);

  if (isEmpty(placement)) {
    return null;
  }

  return (
    <div className="mn_creditCardMarketingBannerWrapper">
      <div className="mn_creditCardMarketingBanner">
        <div className="mn_creditCardImageWrap">
          <img src={image} alt={imageAlternateText} />
        </div>
        <div className="mn_creditCardMarketingBannerInsideWrapper">
          <div className="mn_creditCardInfoWrap">
            {showCardTip && <div className="mn_cardTip">{rolloverCopy}</div>}
            {shouldShowCardHeading && <h2>{shortSummaryText}</h2>}
            <p dangerouslySetInnerHTML={{ __html: text }} />
            {shouldShowCardTerms && <div className="mn_cardTerms">Terms apply.</div>}
          </div>
          <a
            className={`${shouldShowButtonView ? 'mn_button mn_secondaryButton' : 'mn_externalLink'}`}
            href={clickUrl}
            {...addExternalAttributesIfNeeded(clickUrl, siteUrl)}
          >
            {callToActionText}
          </a>
        </div>
      </div>
    </div>
  );
}

export default (props) =>
  <ComponentErrorBoundary><CreditCardMarketingBanner {...props} /></ComponentErrorBoundary>;

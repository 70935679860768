import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { selectCookieDomain } from 'core/selectors/env';
import { selectPageName } from 'core/selectors/app';
import orgConfig from 'org/configs/org.config';
import { triggerOnboardingCongratulationsIsViewed } from './actions';
import config from './configs';

import './HeaderOnboardingCongratulations.scss';

function HeaderOnboardingCongratulations() {
  const cookieDomain = useSelector(selectCookieDomain);
  const pageName = useSelector(selectPageName);
  const [showCongratulations, setShowCongratulations] = useState(false);
  const dispatch = useDispatch();

  const { earnType } = orgConfig;
  const { getCongratulationText, image } = config;
  const congratulationText = getCongratulationText(earnType);

 const handleVisibilityChange = () => {
  if (!document.hidden) {
    setShowCongratulations(true);
    dispatch(triggerOnboardingCongratulationsIsViewed(pageName));
    Cookies.remove('showOnboardingCongratulations', { path: '', domain: cookieDomain });
    setTimeout(() => setShowCongratulations(false), 6000);
  }
 };

 useEffect(() => {
  if (Cookies.get('showOnboardingCongratulations') === '1') {
    handleVisibilityChange();
    document.addEventListener('visibilitychange', handleVisibilityChange);
  }

  return () => { document.removeEventListener('visibilitychange', handleVisibilityChange); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [showCongratulations]);

  if (!showCongratulations) {
    return null;
  }

  return (
    <div className="mn_onboardingCongratsWrap mn_contentSection">
      <div id="mn_onboardingCongrats" className="mn_onboardingCongrats">
        <h2><img src={image} alt="" />Congratulations</h2>
        <p>{congratulationText}</p>
      </div>
    </div>
  );
}

export default HeaderOnboardingCongratulations;

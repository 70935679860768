import ReactGA from 'react-ga4';
import { cacheStorageCache } from 'core/utils/cache/cacheStorage';
import { getParamsFromSearch } from 'core/utils/helpers/getParamsFromSearch';
import {
  trackInsiderInterstitialButtonClickEvent,
  trackInsiderInterstitialMallClickEvent,
  trackInsiderInterstitialPurchaseClickEvent,
} from 'core/utils/analytics/insiderAnalytics';
import addRavenIndicatorToBody from './addRavenIndicatorToBody';

const BUTTON_CLICK_P_PARAM_VALUE = '3058';
const BUTTON_IOS_CLICK_P_PARAM_VALUE = '3679';

const interstitial = () => {
  const merchInterstitial = document.getElementById('mn_merchInterstitial');
  const initParams = document.getElementById('mn_initParams');
  const isContentPresent = !!document.getElementById('mn_istitContent');

  if (merchInterstitial && initParams) {
    if (isContentPresent) {
      addRavenIndicatorToBody();
    }
    // check if we're on 'pause' interstitial
    const isInterstitialTypePause = document.getElementsByClassName('mn_istitTypePause').length;
    // check if we're on 'continue' interstitial
    const isInterstitialTypeContinue = document.getElementsByClassName('mn_istitTypeContinue').length;
    // check if we're on 'ITP' interstitial
    const isInterstitialTypeITP = document.getElementsByClassName('mn_istitTypeITP').length;

    // show loading animation and message
    if (!isInterstitialTypePause) {
      const sBar = document.createElement('div');
      const newContent = document.createTextNode('one moment please');
      sBar.appendChild(newContent);
      sBar.id = 'mn_statusBar';

      const istitTansMsg = document.getElementsByClassName('mn_istitTansMsg')[0];

      if (istitTansMsg) {
        istitTansMsg.prepend(sBar);
      }
    }

    const institPauseId = document.getElementById('mn_institPause');

    if (institPauseId) {
      document.getElementById('mn_institPause').style.display = 'inline';
    }

    // timed redirect
    const params = document.querySelectorAll('#mn_initParams span');
    const setTime = params[1].innerText; // get the time
    const redUrl = params[0].innerText; // url to go to
    const isCJ = params[3].innerText === '2';

    if (isInterstitialTypePause) {
      // get the pause-timer timeout (defined by client) from parameters
      const initialPauseTimeSeconds = parseInt(setTime / 1000, 10);
      document.getElementById('mn_statusBarTimerSeconds').innerText = initialPauseTimeSeconds;
    }

    if (isInterstitialTypeContinue || isInterstitialTypeITP) {
      const istitContinue = document.getElementById('mn_istitContinue');
      istitContinue.addEventListener('click', () => {
        if (typeof cj !== 'undefined' && isCJ) {
          // eslint-disable-next-line
          cj.navigate(redUrl);
        } else {
          window.location = redUrl;
        }
      });
    }

    if (setTime !== '' && redUrl !== '') {
      let redirectTimer;
      let pauseTimer;

      const setRedirectTimer = (timeout) => {
        const redirectFunction = () => {
          if (typeof cj !== 'undefined' && isCJ) {
            // eslint-disable-next-line
            cj.navigate(redUrl);
          } else {
            window.location = redUrl;
          }
        };

        redirectTimer = setTimeout(redirectFunction, timeout);
      };

      const setPauseTimer = () => {
        const pauseTimerFunction = () => {
          const currentTimerValue =
            parseInt(document.getElementById('mn_statusBarTimerSeconds').innerText, 10);

          if (currentTimerValue === 1) {
            clearInterval(pauseTimer);
            setRedirectTimer(0);
          }

          document.getElementById('mn_statusBarTimerSeconds').innerText = currentTimerValue - 1;
        };

        pauseTimer = setInterval(pauseTimerFunction, 1000);
      };

      // for pause interstitial set "pause" timer
      // for other interstitials - set redirect timeout
      if (isInterstitialTypePause) {
        setPauseTimer();
      } else {
        setRedirectTimer(setTime);
      }

      // when user click on "Pause" button
      const institPause = document.getElementById('mn_institPause');

      if (institPause) {
        institPause.onclick = () => {
          // clear the redirect timer
          redirectTimer && clearTimeout(redirectTimer);

          // if we're on the "interstitial-pause" page
          if (isInterstitialTypePause) {
            pauseTimer && clearInterval(pauseTimer);

            // hide "Pause" & show "Continue" button
            document.getElementById('mn_institPause').style.display = 'none';
            document.getElementById('mn_institPause').classList.add('mn_hidden');

            document.getElementById('mn_istitContinue').style.display = 'block';
            document.getElementById('mn_istitContinue').classList.remove('mn_hidden');
          } else {
            const statusBar = document.getElementById('mn_statusBar');
            // if we're on NON "interstitial-pause" page
            if (statusBar) {
              statusBar.style.display = 'none';
            }

            institPause.innerText = 'Continue';
            institPause.classList.remove('mn_institPause');
            institPause.classList.add('mn_institContinue');
          }
        };
      }

      if (isInterstitialTypePause) {
        const istitContinue = document.getElementById('mn_istitContinue');

        istitContinue.addEventListener('click', () => {
          // if we're on pause-type interstitial
          if (isInterstitialTypePause) {
            // redirect user right after he click on "Continue" button
            setRedirectTimer(0);
          }
          if (typeof cj !== 'undefined' && isCJ) {
            // eslint-disable-next-line
            cj.navigate(redUrl);
          } else {
            window.location = redUrl;
          }
        });
      }
    }
  }
};
/**
 * close the interstitial page
 *
 * To-do tetsing/updates, chase usecase for future:
 * The click interstitial page may be opened in a webview where we may have issues using window object.
 * This function attempts to close the window via window.close().
 * If window.close succeeded, then preventDefault and finish.
 * If window.close failed, then check if we have the me____.htm link set up in the click template.
 * If it does, then do nothing and allow default event to proceed.
 * If we do not have me____.htm link in the template, attempt to use window.location.
 * @return void
 */
const closeInterstitial = () => {
  const istitCancel = document.getElementById('mn_istitCancel');
  const istitClose = document.getElementById('mn_istitClose');

  const closeFunction = (e) => {
    const clickWindow = window.close();

    if (clickWindow && clickWindow.closed) {
      e.preventDefault();
    } else {
      // closing window didn't work - try to redirect the user back to the
      // associated me page of this click link's merchant
      // eslint-disable-next-line
      if (!(e.target.href && e.target.href.indexOf('/me____.htm') !== -1)) {
        const { gmid } = getParamsFromSearch();

        if (gmid) {
          e.preventDefault();
          window.location = `/me____.htm?gmid=${gmid}`;
        }
      }
    }
  };

  if (istitCancel) {
    istitCancel.addEventListener('click', closeFunction);
  }

  if (istitClose) {
    istitClose.addEventListener('click', closeFunction);
  }
};

const registerInterstitialITPGAEvents = () => {
  function sendGAEventForInterstitialITP(eventAction) {
    const { app: { features: { ga } } } = window.initialState;
    const merchantName = document.getElementById('mn_istitBody').getAttribute('data-merchant-name') ?? '';
    const eventCategory = 'Safari Stop interstitial';

    ReactGA.event(eventCategory, {
      eventCategory,
      eventAction,
      eventLabel: merchantName,
      eventValue: 0,
      send_to: ga.tag,
      transport: 'beacon',
    });
  }

    const istitTypeITPElement = document.getElementsByClassName('mn_istitTypeITP');

    istitTypeITPElement && istitTypeITPElement.length && sendGAEventForInterstitialITP('View');

    const itpChromeElement = document.querySelector('body .mn_ITPChrome');
    const itpFirefoxElement = document.querySelector('body .mn_ITPFirefox');
    const itpCancelElement = document.querySelector('body .mn_ITPCancel');
    const itpContinueElement = document.querySelector('body .mn_ITPContinue');

    if (itpChromeElement) {
      itpChromeElement.addEventListener('click', () => { sendGAEventForInterstitialITP('Chrome Click'); });
    }

    if (itpFirefoxElement) {
      itpFirefoxElement.addEventListener('click', () => { sendGAEventForInterstitialITP('Firefox Click'); });
    }

    if (itpCancelElement) {
      itpCancelElement.addEventListener('click', () => { sendGAEventForInterstitialITP('Cancel Click'); });
    }

    if (itpContinueElement) {
      itpContinueElement.addEventListener('click', () => { sendGAEventForInterstitialITP('Continue Anyway Click'); });
    }
};

const updateRecentStoresFlyoutCaching = async () => {
  const { gmid } = getParamsFromSearch();
  const clickGmid = gmid && parseInt(gmid, 10);
  const { user: { userId }, app: { features: { recentStoresFlyoutCacheKey, enableRecentStoresFlyout } } } =
    window.initialState;

  if (clickGmid && enableRecentStoresFlyout && userId) {
    const cacheHit = await cacheStorageCache.getItem(recentStoresFlyoutCacheKey) || {};
    const { gmids } = cacheHit;

    if (gmids) {
      gmids.unshift(clickGmid);

      /**
       * Worst case scenerio if we have 1000 clicks to same merchant:
       * dedupe gmids to be safe to not running out of memory  and to avoid any potential
       * user experience issues
       * @type {*[]}
       */
      const uniqueGmids = [];

      gmids.forEach((gmid) => {
        if (uniqueGmids.indexOf(gmid) === -1) {
          uniqueGmids.push(gmid);
        }
      });

      cacheHit.gmids = uniqueGmids;
      cacheStorageCache.setItem(recentStoresFlyoutCacheKey, cacheHit);
    }
  }
};

const sendInsiderAddToCardEvent = () => {
  const { gmid, p } = getParamsFromSearch();
  const { app: { features: { insider } } } = window.initialState;

  if (!gmid || !insider.isEnabled) {
    return;
  }

  trackInsiderInterstitialPurchaseClickEvent(gmid);

  if (p === BUTTON_CLICK_P_PARAM_VALUE || p === BUTTON_IOS_CLICK_P_PARAM_VALUE) {
    trackInsiderInterstitialButtonClickEvent(gmid);
  } else {
    trackInsiderInterstitialMallClickEvent(gmid);
  }
};

export default function init() {
  interstitial();
  closeInterstitial();
  registerInterstitialITPGAEvents();
  updateRecentStoresFlyoutCaching();
  sendInsiderAddToCardEvent();
}
